import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const underlineVariants = {
  hidden: { scaleX: 0 },
  visible: { scaleX: 1 },
};

interface MenuLinksProps {
  activeLink: string;
  setActiveLink: (link: string) => void;
}

const MenuLinks: React.FC<MenuLinksProps> = ({ activeLink, setActiveLink }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between mt-8 sm:mt-16 bg-white bg-opacity-5 w-full py-8 sm:py-16 space-y-4 sm:space-y-0">
      <div className="flex-1 flex justify-center">
        <motion.div whileHover="visible" initial="hidden" className="relative">
          <NavLink
            to="/menus/sb"
            className={`font-paperboard text-2xl sm:text-4xl ${activeLink === 'sb' ? 'text-white' : 'text-gray-400'}`}
            onClick={() => setActiveLink('sb')}
          >
            Santa Barbara
          </NavLink>
          <motion.span
            className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
            variants={underlineVariants}
            initial="hidden"
            animate={activeLink === 'sb' ? 'visible' : 'hidden'}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      </div>
      <div className="flex-1 flex justify-center">
        <motion.div whileHover="visible" initial="hidden" className="relative">
          <NavLink
            to="/menus/np"
            className={`font-paperboard text-2xl sm:text-4xl ${activeLink === 'np' ? 'text-white' : 'text-gray-400'}`}
            onClick={() => setActiveLink('np')}
          >
            Newbury Park
          </NavLink>
          <motion.span
            className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
            variants={underlineVariants}
            initial="hidden"
            animate={activeLink === 'np' ? 'visible' : 'hidden'}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default MenuLinks;