// src/components/common/MenuLinks.tsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const underlineVariants = {
  hidden: { scaleX: 0 },
  visible: { scaleX: 1 },
};

const MenuLinks: React.FC = () => {
  const [activeLink, setActiveLink] = useState<string | null>(null);

  return (
    <div className="flex flex-col sm:flex-row justify-center items-center bg-white bg-opacity-5 w-full py-8 sm:py-16 space-y-4 sm:space-y-0 sm:space-x-8">
      <motion.div whileHover="visible" initial="hidden" className="relative mx-4 sm:mx-8 text-center">
        <NavLink
          to="/locations/sb"
          className={`font-paperboard text-2xl sm:text-4xl ${activeLink === 'sb' ? 'text-white' : 'text-gray-400'}`}
          onClick={() => setActiveLink('sb')}
        >
          Santa Barbara
        </NavLink>
        <motion.span
          className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
          variants={underlineVariants}
          initial="hidden"
          animate={activeLink === 'sb' ? 'visible' : 'hidden'}
          transition={{ duration: 0.3 }}
          style={{ transformOrigin: 'center' }}
        />
      </motion.div>
      <motion.div whileHover="visible" initial="hidden" className="relative mx-4 sm:mx-8 text-center">
        <NavLink
          to="/locations/np"
          className={`font-paperboard text-2xl sm:text-4xl ${activeLink === 'np' ? 'text-white' : 'text-gray-400'}`}
          onClick={() => setActiveLink('np')}
        >
          Newbury Park
        </NavLink>
        <motion.span
          className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
          variants={underlineVariants}
          initial="hidden"
          animate={activeLink === 'np' ? 'visible' : 'hidden'}
          transition={{ duration: 0.3 }}
          style={{ transformOrigin: 'center' }}
        />
      </motion.div>
    </div>
  );
};

export default MenuLinks;