import React from 'react';
import { observer } from 'mobx-react-lite';
import contactStore from '../../stores/ContactFormStore';

const ContactForm: React.FC = observer(() => {
  return (
    <div className="bg-white shadow-lg w-full max-w-lg mx-auto p-4 sm:p-8 text-black">
      <form
        className="space-y-8 sm:space-y-12 mt-8"
        onSubmit={(e) => {
          e.preventDefault();
          contactStore.submitForm();
        }}
      >
        <div>
          <label htmlFor="name" className="block text-sm sm:text-md font-paperboard">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-2 sm:px-4 py-2 border-b border-black focus:outline-none"
            value={contactStore.formData.name}
            onChange={(e) => contactStore.setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm sm:text-md font-paperboard">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            className="w-full px-2 sm:px-4 py-2 border-b border-black focus:outline-none"
            value={contactStore.formData.email}
            onChange={(e) => contactStore.setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm sm:text-md font-paperboard">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="w-full px-2 sm:px-4 py-2 border-b border-black focus:outline-none"
            value={contactStore.formData.phone}
            onChange={(e) => contactStore.setPhoneNumber(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="location" className="block text-sm sm:text-md font-paperboard">Location</label>
          <select
            id="location"
            name="location"
            className="w-full px-2 sm:px-4 py-2 border-b border-black focus:outline-none"
            value={contactStore.formData.location}
            onChange={(e) => contactStore.setLocation(e.target.value)}
          >
            <option value="Santa Barbara">Santa Barbara</option>
            <option value="Newbury Park">Newbury Park</option>
          </select>
        </div>
        <div>
          <label htmlFor="message" className="block text-sm sm:text-md font-paperboard">Message</label>
          <textarea
            id="message"
            name="message"
            rows={5}
            className="w-full px-2 sm:px-4 py-2 border border-black rounded-md focus:outline-none"
            value={contactStore.formData.message}
            onChange={(e) => contactStore.setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="px-6 py-2 bg-black text-white rounded-md"
            disabled={contactStore.isLoading}
          >
            {contactStore.isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </form>
    </div>
  );
});

export default ContactForm;