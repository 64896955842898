// src/stores/CategoryStore.ts
import { makeAutoObservable } from "mobx";
import API from "../api/client";
import { Category } from "../types";

class CategoryStore {
  categories: Category[] = [];
  loading: boolean = false;
  error: string | null = null;
  selectedMenu: string = "Santa Barbara Menu";

  constructor() {
    makeAutoObservable(this);
    this.fetchCategoriesByMenu(this.selectedMenu);
  }

  fetchCategories = async () => {
    this.loading = true;
    this.error = null;
    try {
      const response = await API.get<Category[]>("/api/categories/");
      this.categories = response.data;
    } catch (error: any) {
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  };

  fetchCategoriesByMenu = async (menuName: string) => {
    this.loading = true;
    this.error = null;
    try {
      const response = await API.get<Category[]>("/api/categories/", {
        params: { menu: menuName },
      });
      this.categories = response.data;
      this.selectedMenu = menuName;
    } catch (error: any) {
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  };

  setSelectedMenu = (menuName: string) => {
    this.selectedMenu = menuName;
    this.fetchCategoriesByMenu(menuName);
  };
}

const categoryStore = new CategoryStore();
export default categoryStore;