// src/pages/locations/SantaBarbaraLocation.tsx
import React from 'react';
import beautifulSvg from '../../assets/images/Beautiful-Little-SVG.svg';
import ElegantLine from '../../assets/images/ElegantLine.svg';
import SantaBarbaraMenu from '../Menu/SantaBarbaraMenu';
import { ReactComponent as LocationBubbleSvg } from '../../assets/images/location-bubble.svg';

const SantaBarbaraLocation: React.FC = () => {
  return (
    <div className="mt-8 sm:mt-16 md:mt-24 space-y-12 sm:space-y-16 md:space-y-24">
      <img src={beautifulSvg} alt="Beautiful SVG" className="w-12 h-12 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 mx-auto mt-4 sm:mt-8" />
      <div className="flex flex-col items-center justify-center mt-4 text-center">
        <LocationBubbleSvg className="w-8 h-8 mb-2 sm:mb-4" />
        <p className="font-satoshi font-medium leading-tight">
          512 State Street, Santa Barbara, CA
          <br className='leading-none'/>
          805-965-3363
        </p>
      </div>
      <h3 className="font-paperboard text-4xl sm:text-6xl md:text-8xl text-center">SANTA BARBARA</h3>
      <p className="text-center mx-4 text-xl sm:text-2xl md:text-3xl font-paperboard leading-tight">
        Welcome to Holdren's Santa Barbara, where culinary excellence meets a cozy, inviting atmosphere. 
        <br className='leading-none'/>
        Join us for a meal and experience the best in steak and seafood.
      </p>
      <div className="flex flex-col sm:flex-row justify-center items-center mb-8 sm:mb-16">
        <img src={"https://holdrens-bucket.s3.amazonaws.com/holdrensSbInterior.webp"} alt="Serving Tables" className="w-full sm:w-1/2 h-auto mb-4 sm:mb-0 sm:mr-4" />
        <img src={"https://holdrens-bucket.s3.amazonaws.com/holdrensCounter.webp"} alt="Vintage Chairs" className="w-full sm:w-1/2 h-auto sm:ml-4" />
      </div>
      <div className="text-center">
        <h1 className="font-paperboard text-4xl sm:text-6xl md:text-8xl mb-4">Menu</h1>
        <div className="flex justify-center mb-6">
          <img src={ElegantLine} alt="Elegant Line" className="w-20 sm:w-24 md:w-36" />
        </div>
      </div>
      <SantaBarbaraMenu />
      <img src={beautifulSvg} alt="Beautiful SVG" className="w-12 h-12 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 mx-auto mt-8" />
    </div>
  );
};

export default SantaBarbaraLocation;