import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import categoryStore from '../../stores/CategoryStore';
import PdfStore from '../../stores/PdfStore';
import CategoryList from '../common/CategoryList';
import MenuItem from './MenuItems';
import CombinationAndSidesMenuItem from './MenuItems/CombinationAndSidesMenuItem';
import HeritageInfinity from '../../assets/images/Heritage-Infinity.svg';
import ElegantLine from '../../assets/images/ElegantLine.svg';

const SantaBarbaraMenu: React.FC = observer(() => {
  const sectionRefs = useRef<Record<number, HTMLDivElement | null>>({});
  const [visibleSections, setVisibleSections] = useState<Set<number>>(new Set());

  useEffect(() => {
    const fetchData = async () => {
      await categoryStore.fetchCategoriesByMenu('Santa Barbara Menu');
      await PdfStore.fetchPdfs();

      const currentRefs = sectionRefs.current;

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setVisibleSections(prev => new Set(prev).add(Number(entry.target.getAttribute('data-category-id'))));
            }
          });
        },
        {
          threshold: 0.1, // Trigger when 10% of the component is visible
        }
      );

      Object.values(currentRefs).forEach(ref => {
        if (ref) observer.observe(ref);
      });

      return () => {
        Object.values(currentRefs).forEach(ref => {
          if (ref) observer.unobserve(ref);
        });
      };
    };

    fetchData();
  }, []);

  const handleCategoryClick = (categoryId: number) => {
    const section = sectionRefs.current[categoryId];
    if (section) {
      const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - window.innerHeight * 0.15; // Adjust offset based on viewport height

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="mt-8 sm:mt-16 md:mt-24 space-y-12 sm:space-y-16 md:space-y-24">
      <CategoryList menu="Santa Barbara Menu" onCategoryClick={handleCategoryClick} />
      {categoryStore.categories.map((category, index) => {
        const isOdd = index % 2 === 1;
        const SvgLeft = isOdd ? ElegantLine : HeritageInfinity;
        const SvgRight = isOdd ? ElegantLine : HeritageInfinity;
        const svgSize = SvgLeft === HeritageInfinity ? "w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24" : "w-16 h-16 sm:w-24 sm:h-24 md:w-32 lg:w-40 xl:w-48";

        const pdfUpload = PdfStore.getPdfByCategory(category.id);

        return (
          <div
            key={category.id}
            ref={(el) => (sectionRefs.current[category.id] = el)}
            data-category-id={category.id}
            className="mb-12 sm:mb-16 md:mb-24"
          >
            <div className="text-center mb-12 sm:mb-16 md:mb-24 relative flex flex-col sm:flex-row items-center justify-center">
              <img src={SvgLeft} alt="Left SVG" className={`mb-4 sm:mb-0 ${svgSize}`} />
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-paperboard mx-4">{category.name}</h2>
              <img src={SvgRight} alt="Right SVG" className={`mt-4 sm:mt-0 ${svgSize}`} />
            </div>
            {pdfUpload ? (
              <div className="text-center">
                <a
                  href={pdfUpload.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block px-4 py-2 sm:px-6 sm:py-3 text-xl sm:text-2xl md:text-3xl font-paperboard text-white bg-holdrens-red hover:bg-holdrens-gold rounded-full shadow-md transition duration-300"
                >
                  View {category.name}
                </a>
              </div>
            ) : (
              visibleSections.has(category.id) ? (
                category.name === 'Combinations & Sides' ? (
                  <CombinationAndSidesMenuItem categoryId={category.id} />
                ) : (
                  <MenuItem categoryId={category.id} />
                )
              ) : (
                <div className="text-center text-lg">Loading...</div>
              )
            )}
          </div>
        );
      })}
    </div>
  );
});

export default SantaBarbaraMenu;