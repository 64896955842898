import React from 'react';
import { ReactComponent as BeautifulSvg } from '../../assets/images/Beautiful-Little-SVG.svg';
import fbLogo from '../../assets/images/fb-logo.svg';
import instagramLogo from '../../assets/images/instagram-logo.svg';

const ContactInfo: React.FC = () => (
  <div className="flex flex-col justify-center items-center h-full px-4">
    <div className="text-center">
      <BeautifulSvg className="w-24 h-24 sm:w-40 sm:h-40 mb-4 mx-auto transform rotate-180" />
      <p className="text-3xl sm:text-5xl font-paperboard leading-tight">Call us at</p>
      <p className="text-3xl sm:text-5xl font-paperboard leading-tight mt-2">805-965-3363</p>
      <p className="text-lg sm:text-2xl mt-2 font-satoshi">for Santa Barbara reservations and inquiries</p>
      <p className="text-xl sm:text-3xl font-paperboard mt-4">or</p>
      <p className="text-3xl sm:text-5xl font-paperboard mt-2">805-498-1314</p>
      <p className="text-lg sm:text-2xl mt-2 font-satoshi">for Newbury Park reservations and inquiries</p>
      <div className="mt-8 flex justify-center space-x-4">
        <a href="https://www.facebook.com/holdrens.santabarbara/" target="_blank" rel="noopener noreferrer">
          <img src={fbLogo} alt="Facebook" className="w-6 h-6 sm:w-8 sm:h-8" />
        </a>
        <a href="https://www.instagram.com/holdrens_sb" target="_blank" rel="noopener noreferrer">
          <img src={instagramLogo} alt="Instagram" className="w-6 h-6 sm:w-8 sm:h-8" />
        </a>
      </div>
      <BeautifulSvg className="w-24 h-24 sm:w-40 sm:h-40 mt-8 mx-auto" />
    </div>
  </div>
);

export default ContactInfo;