import { makeAutoObservable, runInAction } from "mobx";
import API from "../api/client";
import { MenuItem } from "../types";

class MenuItemStore {
  menuItemsByCategory: Record<number, MenuItem[]> = {};
  loading: Record<number, boolean> = {};
  error: Record<number, string | null> = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchMenuItemsByCategory(categoryId: number) {
    // Avoid fetching if data already exists for this category
    if (this.menuItemsByCategory[categoryId] && !this.loading[categoryId]) {
      return;
    }

    this.loading[categoryId] = true;
    this.error[categoryId] = null;

    API.get(`/api/menu-items/?category=${categoryId}`)
      .then((response) => {
        runInAction(() => {
          this.menuItemsByCategory[categoryId] = response.data;
          this.loading[categoryId] = false;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.error[categoryId] = error.message;
          this.loading[categoryId] = false;
        });
      });
  }

  getMenuItemsByCategory(categoryId: number): MenuItem[] {
    return this.menuItemsByCategory[categoryId] || [];
  }
}

const menuItemStore = new MenuItemStore();
export default menuItemStore;