import React, { useState } from 'react';
import Logo from '../Header/Logo';
import NavLinks from '../Header/NavLinks';
import ReservationButton from '../Header/ReservationButton';
import ReservationDropdown from '../Header/ReservationDropdown';
import fbLogo from '../../../assets/images/fb-logo.svg';
import instagramLogo from '../../../assets/images/instagram-logo.svg';
import beautifulSvg from '../../../assets/images/Beautiful-Little-SVG.svg';

const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <footer className="bg-black text-white px-6 py-8 xl:px-24 xl:py-12 relative z-10">
      <div className="flex flex-col items-center xl:flex-row xl:items-start xl:justify-between">
        <div className="flex flex-col items-center xl:flex-row xl:items-start xl:space-y-0 xl:space-x-8 mb-8 xl:mb-0">
          <div className="mb-4 xl:mb-0 xl:mr-12">
            <Logo />
          </div>
          <div className="flex flex-col items-center xl:items-start space-y-4">
            <div className="flex flex-col items-center xl:flex-row xl:items-center xl:space-x-8">
              <NavLinks dropUp />
              <div className="mt-4 xl:mt-0 xl:ml-8 relative">
                <ReservationButton toggleModal={toggleModal} />
              </div>
            </div>
            <div className="flex flex-col items-center xl:flex-row xl:items-center xl:space-x-8 mt-4 xl:mt-0">
              <div className="text-center xl:text-left">
                <h3 className="font-paperboard">SANTA BARBARA</h3>
                <p className="font-satoshi">
                  512 State Street, Santa Barbara, CA<br />
                  805-965-3363
                </p>
              </div>
              <img src={beautifulSvg} alt="Beautiful SVG" className="w-12 h-12 mx-auto" />
              <div className="text-center xl:text-left">
                <h3 className="font-paperboard">NEWBURY PARK</h3>
                <p className="font-satoshi">
                  1714-A Newbury Road, Newbury Park, CA<br />
                  805-498-1314
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center xl:flex-col xl:items-start space-x-6 xl:space-x-0 xl:space-y-6">
          <a href="https://www.facebook.com/holdrens.santabarbara/" target="_blank" rel="noopener noreferrer">
            <img src={fbLogo} alt="Facebook" className="w-8 h-8" />
          </a>
          <a href="https://www.instagram.com/holdrens_sb" target="_blank" rel="noopener noreferrer">
            <img src={instagramLogo} alt="Instagram" className="w-8 h-8" />
          </a>
          {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <img src={twitterLogo} alt="Twitter" className="w-8 h-8" />
          </a> */}
        </div>
      </div>
      <div className="mt-4 xl:mt-0">
        <ReservationDropdown isModalOpen={isModalOpen} toggleModal={toggleModal} footer />
      </div>
    </footer>
  );
};

export default Footer;