import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import menuItemStore from '../../../stores/MenuItemStore';
import { MenuItem as MenuItemType } from '../../../types';
import { motion } from 'framer-motion';

interface MenuItemProps {
  categoryId: number;
}

const CombinationAndSidesMenuItem: React.FC<MenuItemProps> = observer(({ categoryId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cleanup = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          menuItemStore.fetchMenuItemsByCategory(categoryId);
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the component is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (cleanup) {
        observer.unobserve(cleanup);
      }
    };
  }, [categoryId]);

  if (!isVisible) {
    return <div ref={ref}>Loading...</div>;
  }

  if (menuItemStore.loading[categoryId]) {
    return <div>Loading...</div>;
  }

  if (menuItemStore.error[categoryId]) {
    return <div>Error: {menuItemStore.error[categoryId]}</div>;
  }

  const menuItems = menuItemStore.getMenuItemsByCategory(categoryId);
  const combinationsItems = menuItems.filter(item => item.subcategory?.name === 'Combinations');
  const sidesItems = menuItems.filter(item => item.subcategory?.name === 'Sides');

  const formatPrice = (price: number) => {
    return price != null ? `$${Math.round(price)}` : 'MP';
  };

  const groupCombinations = (items: MenuItemType[]) => {
    const groupedItems: Record<string, { title: string, prices: string[] }> = {};

    items.forEach(item => {
      const key = item.title.replace(/\(\d+ OZ\.\)/i, '').trim();
      if (!groupedItems[key]) {
        groupedItems[key] = { title: key, prices: [] };
      }
      if (item.price) {
        if (item.description) {
          groupedItems[key].prices.push(`${item.description} ${formatPrice(item.price)}`);
        } else {
          groupedItems[key].prices.push(formatPrice(item.price));
        }
      }
    });

    return Object.values(groupedItems);
  };

  const groupedCombinations = groupCombinations(combinationsItems);

  return (
    <div ref={ref} className="flex flex-col items-center text-center px-4 sm:px-0">
      {/* Combinations */}
      <div className="w-full md:w-2/3 mb-16 mx-auto">
        {groupedCombinations.map((item, index) => (
          <motion.div
            key={index}
            className="mb-4 flex flex-col sm:flex-row justify-between items-center w-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center w-full">
              <h4 className="text-xl sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words">{item.title}</h4>
              <div className="flex-grow mx-4 h-0.5 bg-holdrens-red block"></div>
              <div className="text-xl sm:text-2xl font-paperboard text-left sm:text-right whitespace-normal overflow-wrap break-words mt-2 sm:mt-0 sm:ml-4">
                {item.prices.join(' | ') || 'MP'}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      {/* Sides */}
      <div className="w-full md:w-2/3 mb-16 mx-auto">
        {sidesItems.map((item: MenuItemType) => (
          <motion.div
            key={item.id}
            className="mb-4 flex flex-col sm:flex-row justify-between items-center w-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex items-center w-full">
              <div className="text-xl sm:text-2xl font-paperboard text-left sm:text-right whitespace-normal overflow-wrap break-words mb-2 sm:mb-0 sm:mr-4">
                {item.price != null ? formatPrice(item.price) : 'N/A'}
              </div>
              <div className="flex-grow mx-4 h-0.5 bg-holdrens-red block"></div>
              <h4 className="text-xl sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words">{item.title}</h4>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
});

export default CombinationAndSidesMenuItem;