import React, { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import Root from './components/Root';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorBoundary from './components/Root/ErrorBoundary';
import Home from './components/Home';
import ContactUs from './components/ContactUs';
import CateringSection from './components/Catering';
import Menu from './components/Menu';
import SantaBarbaraMenu from './components/Menu/SantaBarbaraMenu';
import NewburyParkMenu from './components/Menu/NewburyParkMenu';
import Locations from './components/Locations';
import SantaBarbaraLocation from './components/Locations/SantaBarbaraLocation';
import NewburyParkLocation from './components/Locations/NewburyParkLocation';
import AboutUs from './components/AboutUs';

const App = () => {
  return (
    <Root>
      <ErrorBoundary>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
        <Suspense fallback={<div>Loading...</div>}>
          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/contact-us" element={<ContactUs />}/>
              <Route path="/about-us" element={<AboutUs />}/>
              <Route path="/catering" element={<CateringSection />}/>
              <Route path="/menus" element={<Menu />}>
                <Route path="sb" element={<SantaBarbaraMenu />} />
                <Route path="np" element={<NewburyParkMenu />} />
              </Route>
              <Route path="/locations" element={<Locations />}>
                <Route path="sb" element={<SantaBarbaraLocation />} />
                <Route path="np" element={<NewburyParkLocation />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AnimatePresence>
        </Suspense>
        </ErrorBoundary>
    </Root>
  );
};

export default App;