import React from 'react';
import HeroSection from './HeroSection';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import LocationSection from './LocationSection';

const ContactUs: React.FC = () => {
  return (
    <>
      <HeroSection />
      <section className="py-8 sm:py-12 px-4 md:px-12 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ContactForm />
          <ContactInfo />
        </div>
      </section>
      <LocationSection />
    </>
  );
}

export default ContactUs;