// src/components/MenuItem.tsx
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import menuItemStore from "../../stores/MenuItemStore";
import { MenuItem as MenuItemType } from "../../types";
import { motion } from "framer-motion";

interface MenuItemProps {
  categoryId: number;
}

const MenuItem: React.FC<MenuItemProps> = observer(({ categoryId }) => {
  useEffect(() => {
    menuItemStore.fetchMenuItemsByCategory(categoryId);
  }, [categoryId]);

  if (menuItemStore.loading[categoryId]) {
    return <div>Loading...</div>;
  }

  if (menuItemStore.error[categoryId]) {
    return <div>Error: {menuItemStore.error[categoryId]}</div>;
  }

  const menuItems = menuItemStore.getMenuItemsByCategory(categoryId);
  const subcategories: { [key: string]: MenuItemType[] } = {};

  menuItems.forEach((item) => {
    const subcategoryName = item.subcategory?.name || "";
    if (!subcategories[subcategoryName]) {
      subcategories[subcategoryName] = [];
    }
    subcategories[subcategoryName].push(item);
  });

  const getSubcategoryHeader = (subcategoryName: string) => {
    switch (subcategoryName.toLowerCase()) {
      case "salad":
        return "Choice of two salads:";
      case "entrée":
        return "Choice of two entrée's:";
      case "sides":
        return "Choice of two sides:";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col items-center text-center px-4 mb-8 sm:px-8">
      {Object.keys(subcategories).map((subcategoryName) => (
        <div key={subcategoryName} className="w-full max-w-3xl mb-8">
          {subcategoryName && (
            <>
              <h3 className="text-2xl sm:text-3xl font-paperboard mb-4">{subcategoryName}</h3>
              <p className="text-lg sm:text-2xl font-brush mb-8">{getSubcategoryHeader(subcategoryName)}</p>
            </>
          )}
          {subcategories[subcategoryName].map((item) => (
            <motion.div
              key={item.id}
              className={`mb-4 ${subcategoryName.toLowerCase() === "sides" ? "text-center" : "text-left"}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h4 className="text-lg sm:text-2xl font-paperboard font-bold uppercase">{item.title}</h4>
              <p className="font-paperboard text-base sm:text-xl">{item.description}</p>
            </motion.div>
          ))}
        </div>
      ))}
    </div>
  );
});

export default MenuItem;