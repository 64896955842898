// src/components/Testimonials.tsx
import React from "react";
import beautifulSvg from "../../assets/images/Beautiful-Little-SVG.svg";

const testimonials = [
  {
    quote: "I can not say enough nice things about Holdren's Steak and Seafood! I am from Oklahoma City and planning a wedding welcome dinner from out of state usually isn't the easiest thing to do, but with Clay and Angela it couldn't have been smoother! A few emails and phone calls, a few pictures of the flowers I liked and they managed to make it more beautiful than I could have imagined! They helped me choose my menu and even made the impossible happen with my dessert. They served over 50 people in the most timely matter and everyone's meal was cooked perfectly to their liking. Clay helped me choose my wine list and we couldn't have been happier with his picks! I highly recommend Holdren's for any special event you need planned, they sure made our wedding weekend in beautiful Santa Barbara very special!",
    author: "Nancy Kerrigan"
  },
  {
    quote: "My daughter had her wedding catered by Holdren's and could not have been happier. The set up was professional and elegant, the staff were amazing, and guests were literally raving about the food long after the event.",
    author: "Melissa O'Conner"
  },
  {
    quote: "We just wanted to thank Clay, Chef Sergio Dominguez, Angela and everyone at Holdren's Restaurant for making our wedding such a special and memorable event. You and your team made the whole catering process so effortless, and we appreciate how you worked within our budget and were flexible for creativity to include things like our Polish kielbasa and pierogies with our appetizer service to help make the event unique. From the beginning, we felt very comfortable working with you and knew your team had everything in control. As you now know, my wife is somewhat of a perfectionist, so it was great to be able to rely on you and your team without having to worry about anything. Your typical response of “Yep, we already have that taken care of” to her every question helped make for one very cool and calm wedding day. From all the discussions (and your restaurant's reputation), we knew the food was going to be amazing...but when we started eating, we were blown away. The Tri-tip and Chicken Piccata drew rave reviews from all our guests, as did the garlic Brussels sprouts! And your people made sure everyone's food was warm and tasty and served on time! Everything was seamless and well-orchestrated. It was better than we could have possibly imagined, and we just wanted to thank you so much. We received so many complements from our guests. So, like it or not, I guess you are in the catering business full on, now! D also wants to acknowledge the attention the servers gave to all the guests but particularly to her during cocktail/appetizer hour. D stated that as a bride it was amazing how the servers came up to her multiple times (without being obtrusive) during appetizer hour to make sure she was well taken care of and that was much appreciated. Thank you so much for a fantastic experience, and we would highly recommend your restaurant and your catering services.",
    author: "Larry Kampa"
  }
];

const Testimonials: React.FC = () => {
  return (
    <div className="py-12">
      <div className="max-w-4xl mx-auto">
        <img src={beautifulSvg} alt="Beautiful SVG" className="py-0 w-16 h-16 mx-auto sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48" />
        <h2 className="text-5xl font-paperboard text-center mb-12">Testimonials</h2>
        <p className="text-lg font-lora text-center mb-8">
          Hear from our satisfied clients! Our catering services have been praised for their professionalism, attention to detail, and, of course, delicious food. Read testimonials from those who have experienced Holdren's Catering.
        </p>
        <div className="flex flex-wrap -mx-4 space-y-8">
          <div className="w-full md:w-1/2 px-4">
            <div className="bg-transparent p-6 flex flex-col justify-center text-left h-full">
              <p className="text-xl font-lora italic mb-2">"{testimonials[0].quote}"</p>
              <p className="text-lg font-paperboard text-holdrens-gold">- {testimonials[0].author}</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div className="bg-gray-200 bg-opacity-10 p-6 flex flex-col justify-center text-center h-full">
              <p className="text-xl font-lora italic mb-2">"{testimonials[1].quote}"</p>
              <p className="text-lg font-paperboard text-holdrens-gold">- {testimonials[1].author}</p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="bg-transparent p-6 text-center">
            <p className="text-xl font-lora italic mb-2">"{testimonials[2].quote}"</p>
            <p className="text-lg font-paperboard text-holdrens-gold">- {testimonials[2].author}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;