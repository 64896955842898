import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { motion } from "framer-motion";
import categoryStore from "../../stores/CategoryStore";

interface CategoryListProps {
  menu: string;
  onCategoryClick: (categoryId: number) => void;
}

const underlineVariants = {
  hidden: { scaleX: 0 },
  visible: { scaleX: 1 },
};

const CategoryList: React.FC<CategoryListProps> = observer(({ menu, onCategoryClick }) => {
  const [clickedCategory, setClickedCategory] = useState<number | null>(null);
  const [hoveredCategory, setHoveredCategory] = useState<number | null>(null);

  useEffect(() => {
    categoryStore.fetchCategoriesByMenu(menu);
  }, [menu]);

  if (categoryStore.loading) {
    return <div>Loading...</div>;
  }

  if (categoryStore.error) {
    return <div>Error: {categoryStore.error}</div>;
  }

  const handleClick = (id: number) => {
    setClickedCategory(id);
    onCategoryClick(id);
  };

  const handleMouseEnter = (id: number) => {
    setHoveredCategory(id);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  return (
    <div className="p-4 pt-8 flex justify-center">
      <ul className="flex flex-wrap justify-center gap-4 max-w-5xl">
        {categoryStore.categories.map((category, index) => (
          <React.Fragment key={category.id}>
            <li
              className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-paperboard cursor-pointer relative"
              onClick={() => handleClick(category.id)}
              onMouseEnter={() => handleMouseEnter(category.id)}
              onMouseLeave={handleMouseLeave}
            >
              <motion.span
                initial={{ WebkitTextFillColor: "transparent", WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: "#ffffff" }}
                animate={{
                  WebkitTextFillColor: clickedCategory === category.id ? "#ffffff" : "transparent",
                  WebkitTextStrokeWidth: clickedCategory === category.id ? "0px" : "1px",
                }}
                transition={{ duration: 0.3 }}
                className={`${clickedCategory === category.id ? "filled" : "outlined"}`}
              >
                {category.name}
              </motion.span>
              <motion.div
                className="absolute left-0 right-0 bottom-0 h-0.5 bg-holdrens-red"
                initial="hidden"
                animate={(hoveredCategory === category.id || clickedCategory === category.id) ? "visible" : "hidden"}
                variants={underlineVariants}
                transition={{ duration: 0.3 }}
              />
            </li>
            {index < categoryStore.categories.length - 1 && (
              <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-paperboard mx-1">,</span>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
});

export default CategoryList;