import React from 'react';
import { ReactComponent as LocationBubbleSvg } from '../../assets/images/location-bubble.svg';

const LocationSection: React.FC = () => {


  return (
    <section className="text-white py-12">
      {/* Santa Barbara Row */}
      <div
        className="flex flex-col md:flex-row mb-12 cursor-pointer"
        onClick={() => window.location.href = '/locations/sb'}
      >
        <div className="md:w-1/2 flex flex-col items-center justify-center text-center mb-4 md:mb-0">
          <h3 className="font-paperboard text-3xl sm:text-4xl md:text-5xl">SANTA BARBARA</h3>
          <div className="flex items-center mt-4">
            <LocationBubbleSvg className="w-4 h-4 mr-2" />
            <p className="font-satoshi font-medium">
              512 State Street, Santa Barbara, CA<br />
            </p>
          </div>
        </div>
        <div className="md:w-1/2 flex">
          <img src={"https://holdrens-bucket.s3.amazonaws.com/holdrensSbInterior.webp"} alt="Santa Barbara Location 1" className="w-1/2 h-auto" />
          <img src={"https://holdrens-bucket.s3.amazonaws.com/holdrensCounter.webp"} alt="Santa Barbara Location 2" className="w-1/2 h-auto" />
        </div>
      </div>

      {/* Newbury Park Row */}
      <div
        className="flex flex-col md:flex-row cursor-pointer"
        onClick={() => window.location.href = '/locations/np'}
      >
        <div className="md:w-1/2 flex order-2 md:order-1">
          <img src={"https://holdrens-bucket.s3.amazonaws.com/np-location-1.webp"} alt="Newbury Park Location 1" className="w-1/2 h-auto" />
          <img src={"https://holdrens-bucket.s3.amazonaws.com/np-location-2.webp"} alt="Newbury Park Location 2" className="w-1/2 h-auto" />
        </div>
        <div className="md:w-1/2 flex flex-col items-center justify-center text-center mb-4 md:mb-0 order-1 md:order-2">
          <h3 className="font-paperboard text-3xl sm:text-4xl md:text-5xl">NEWBURY PARK</h3>
          <div className="flex items-center mt-4">
            <LocationBubbleSvg className="w-4 h-4 mr-2" />
            <p className="font-satoshi font-medium">
              1714-A Newbury Road, Newbury Park, CA<br />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationSection;