import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import MenuImages from './MenuImages';
import MenuLinks from './MenuLinks';

const trifoldImages: Record<string, string[]> = {
  menus: ["https://holdrens-bucket.s3.amazonaws.com/np-steak.webp", "https://holdrens-bucket.s3.us-west-2.amazonaws.com/friedSeafood.webp", "https://holdrens-bucket.s3.amazonaws.com/np-tuna.webp"],
  sb: ['https://holdrens-bucket.s3.amazonaws.com/steak.webp', "https://holdrens-bucket.s3.us-west-2.amazonaws.com/walnut-salad.webp", "https://holdrens-bucket.s3.amazonaws.com/lobster.webp"],
  np: [
    'https://holdrens-bucket.s3.amazonaws.com/np-steak.webp',
    'https://holdrens-bucket.s3.amazonaws.com/np-salad.webp',
    'https://holdrens-bucket.s3.amazonaws.com/np-steak-salad.webp'
  ],
};

const Menu: React.FC = () => {
  const location = useLocation();
  const path = location.pathname.split('/').pop() || 'menus';
  const images = trifoldImages[path] || trifoldImages['menus'];

  const [locationText, setLocationText] = useState<string>('Explore our menus. Select a location to view the menu.');
  const [activeLink, setActiveLink] = useState<string>(path);

  useEffect(() => {
    const getLocationText = () => {
      switch (path) {
        case 'sb':
          return "Discover our carefully curated menu at Holdren's Santa Barbara. From our award-winning steak to our cocktails and fresh seafood, we have something for everybody.";
        case 'np':
          return "Discover our carefully curated menu at Holdren's Newbury Park. From our Happy Hour delights to kid-friendly options and decadent desserts, we have something for everybody.";
        default:
          return 'Explore our menus. Select a location to view the menu.';
      }
    };
    setLocationText(getLocationText());
    setActiveLink(path);
  }, [path]);

  return (
    <div className="text-white">
      <div className="relative flex flex-col justify-center items-center min-h-[60vh] sm:h-screen px-4 sm:px-8">
        <MenuHeader locationText={locationText} />
        <MenuImages images={images} />
      </div>
      <div className="flex justify-center items-center mt-8 mb-4">
        <MenuLinks activeLink={activeLink} setActiveLink={setActiveLink} />
      </div>
      <Outlet />
    </div>
  );
};

export default Menu;