import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import menuItemStore from '../../../stores/MenuItemStore';
import { MenuItem as MenuItemType } from '../../../types';
import { motion } from 'framer-motion';

interface MenuItemProps {
  categoryId: number;
}

const MenuItem: React.FC<MenuItemProps> = observer(({ categoryId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          menuItemStore.fetchMenuItemsByCategory(categoryId);
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the component is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [categoryId]);

  if (!isVisible) {
    return <div ref={ref}>Loading...</div>;
  }

  if (menuItemStore.loading[categoryId]) {
    return <div>Loading...</div>;
  }

  if (menuItemStore.error[categoryId]) {
    return <div>Error: {menuItemStore.error[categoryId]}</div>;
  }

  const menuItems = menuItemStore.getMenuItemsByCategory(categoryId);

  const formatPrice = (price: number) => {
    return price != null ? `$${Math.round(price)}` : 'MP';
  };

  const groupMenuItems = (items: MenuItemType[]) => {
    const groupedItems: Record<string, { title: string, prices: string[], description: string }> = {};

    items.forEach(item => {
      const key = item.title.replace(/\s*$begin:math:text$[^)]*$end:math:text$/g, '').trim();
      const size = item.title.match(/$begin:math:text$[^)]*$end:math:text$/)?.[0]?.replace(/[()]/g, '') ?? '';
      if (!groupedItems[key]) {
        groupedItems[key] = { title: key, prices: [], description: item.description };
      }
      if (item.price) {
        groupedItems[key].prices.push(`${size ? size + '.' : ''} ${formatPrice(item.price)}`);
      }
    });

    return Object.values(groupedItems);
  };

  const groupedMenuItems = groupMenuItems(menuItems);

  return (
    <div ref={ref} className="flex flex-col items-center text-center px-4 sm:px-0">
      {groupedMenuItems.map((item, index) => (
        <motion.div
          key={index}
          className="mb-12 text-left w-full sm:w-2/3 mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col sm:flex-row justify-between items-center w-full">
            <div className="w-full sm:w-1/2">
              <h4 className="text-xl sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words">{item.title}</h4>
            </div>
            <div className="hidden sm:block flex-grow mx-4 relative">
              <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 bg-holdrens-red" style={{ height: '2px' }}></div>
            </div>
            <div className="w-full sm:w-1/3 text-left sm:text-right">
              <p className="text-xl sm:text-2xl font-paperboard">{item.prices.join(' | ') || 'MP'}</p>
            </div>
          </div>
          <div className="mt-2">
            <p className="font-lora text-lg sm:text-xl">{item.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  );
});

export default MenuItem;