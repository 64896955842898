import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as LocationBubbleSvg } from '../../assets/images/location-bubble.svg';

const LocationSection: React.FC = () => (
  <section className="py-12 sm:py-24 text-white">
    <div className="container mx-auto px-4">
      <div className="flex flex-col gap-16 sm:gap-32">
        {/* Santa Barbara Section */}
        <div className="flex flex-col items-center sm:items-start w-full">
          <motion.a 
            href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+512+State+St,+Santa+Barbara,+CA+93101"
            target="_blank" 
            rel="noopener noreferrer"
            className="relative block w-full"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="flex flex-col items-center sm:items-start">
              <h3 className="font-paperboard text-3xl sm:text-4xl">SANTA BARBARA</h3>
              <p className="font-satoshi font-medium flex items-center justify-center mb-0 text-center sm:text-left">
                512 State Street, Santa Barbara, CA
                <LocationBubbleSvg className="w-4 h-4 ml-2" />
              </p>
              <motion.a 
                href="tel:+18059653363" 
                className="font-paperboard font-bold text-lg block mt-2"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                805-965-3363
              </motion.a>
            </div>
            <div className="relative w-full h-auto mt-8 sm:mt-12">
              <img src={"https://holdrens-bucket.s3.amazonaws.com/sb-location.webp"} alt="Santa Barbara Location Map" className="w-full h-auto object-cover" />
              <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            </div>
          </motion.a>
        </div>
        {/* Newbury Park Section */}
        <div className="flex flex-col items-center sm:items-end w-full">
          <motion.a 
            href="https://www.google.com/maps/dir//Holdren's+Steaks+%26+Seafood,+1714-A,+Newbury+Rd,+Newbury+Park,+CA+91320"
            target="_blank" 
            rel="noopener noreferrer"
            className="relative block w-full"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="flex flex-col items-center sm:items-end">
              <h3 className="font-paperboard text-3xl sm:text-4xl">NEWBURY PARK</h3>
              <p className="font-satoshi font-medium flex items-center justify-center mb-0 text-center sm:text-right">
                <LocationBubbleSvg className="w-4 h-4 mr-2" />
                1714-A Newbury Road, Newbury Park, CA
              </p>
              <motion.a 
                href="tel:+18054981314" 
                className="font-paperboard font-bold text-lg block mt-2"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                805-498-1314
              </motion.a>
            </div>
            <div className="relative w-full h-auto mt-8 sm:mt-12">
              <img src={"https://holdrens-bucket.s3.amazonaws.com/newbury-location.webp"} alt="Newbury Park Location Map" className="w-full h-auto object-cover" />
              <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            </div>
          </motion.a>
        </div>
      </div>
    </div>
  </section>
);

export default LocationSection;